import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/pc',
        name: 'pc',
        component: () => import('../views/pc')
    },
    {
        path: '/wap',
        name: 'wap',
        component: () => import('../views/wap')
    }
]

const router = new VueRouter({
    routes
})

export default router
