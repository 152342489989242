<template>
    <div id="app">
        <router-view />
    </div>
</template>

<style lang="less">
#app {
    font-family: "Microsoft Yahei";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>

<script>
export default {
    name: 'App',
    mounted() {
        if (this._isMobile()) {
            this.$router.replace('/wap')
        } else {
            this.$router.replace('/pc')
        }
    },
    methods: {
        _isMobile() {
            const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag
        }
    }
}
</script>

<style lang="less">
body,
html,
#app {
    width: 100%;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
}
</style>
