import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Meta from 'vue-meta'
import './assets/js/common.js'
import axios from 'axios'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

document.title = '878hb.cc'

Vue.use(Meta)

Vue.prototype.$axios = axios

// axios.defaults.baseURL = 'http://ychb.cm/' // 打包时 去掉
axios.interceptors.request.use(config => {
    NProgress.start()
    return config
})

axios.interceptors.response.use(response => {
    NProgress.done()
    return response
}, error => {
    if (error.response) {
        alert('网络错误, 请联系在线客服')
    }
    NProgress.done()
    return Promise.reject(error.response)
})

// 防连点
const pointDoms = [] // 使用这个指令的所有DOM对象
Vue.directive('points', {
    inserted(el, binding) {
        pointDoms.push(el) // 存储使用这个指令的DOM
        el.addEventListener('click', () => {
            // 禁用所有使用这个指令的DOM结构点击事件
            pointDoms.forEach(pointItem => {
                pointItem.style.pointerEvents = 'none'
            })
            setTimeout(() => {
                // 启动所有使用这个指令的DOM结构点击事件
                pointDoms.forEach(pointItem => {
                    pointItem.style.pointerEvents = 'auto'
                })
            }, binding.value || 350)
        })
    }
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
