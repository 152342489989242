import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userData: {
            username: '',
            score: 0
        }
    },
    mutations: {
        setUserData(state, userData) {
            state.userData = userData
        }
    },
    actions: {
    },
    modules: {
    }
})
